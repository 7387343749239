<template>
  <div class="component-wrap" v-if="listShow">
    <div class="component-tit">
      {{ $t("demand.call_for_infor") }}
      <!-- 征集信息 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :allObj="{
          name: $t('CM_All'),
          id: 0,
        }"
        :dataSource="statusOpts"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="taskName"
              :placeholder="
                $t('demand.please_enter_the_name_of_the_demand_collection')
              "
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div class="content list">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <div class="data-card list" @click="viewDetail(item)">
                <div class="detail">
                  <h5>
                    <span class="status bg-gray" v-if="item.status === 1">
                      {{ $t("Pub_Tab_NotStart")
                      }}<!-- 未开始 -->
                    </span>
                    <span class="status bg-green" v-else-if="item.status === 2">
                      {{ $t("XB_UnderWay")
                      }}<!-- 进行中 -->
                    </span>
                    <span
                      class="status bg-orange"
                      v-else-if="item.status === 3"
                    >
                      {{ $t("XB_HasEnded")
                      }}<!-- 已结束 -->
                    </span>
                    <a-popover trigger="hover">
                      <template #content>
                        <div class="popover-content">
                          {{ item.taskName }}
                        </div>
                      </template>
                      {{ item.taskName }}
                    </a-popover>
                  </h5>
                  <div class="row">
                    {{ $t("demand.table.solicitation_year") }}：{{
                      item.taskYear
                    }}
                    <!-- 征集年度 -->
                  </div>
                  <div class="row">
                    <span>{{ $t("demand.table.collection_time") }}：</span
                    ><span class="fs12"
                      >{{ dateFormat(item.startTime) }} ～
                      {{ dateFormat(item.endTime) }}</span
                    >
                    <!-- 征集时间 -->
                  </div>
                </div>
                <div class="btns">
                  <div class="btn">
                    {{ $t("view_detail") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
  <Detail :dataSource="currDetail" @back="detailBack" v-if="detailShow" />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs } from "vue";
import ls from "@/utils/local-storage";
import { dateFormat } from "@/utils/tools";
import { collectList, collectDetail } from "@/api/demand";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import Detail from "./demand/detail.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    renderVNode,
    Detail,
  },
  setup() {
    const { t: $t } = useI18n();
    const state = reactive({
      statusOpts: [
        { id: 1, name: $t("Pub_Tab_NotStart") }, // 未开始
        { id: 2, name: $t("XB_UnderWay") }, // 进行中
        { id: 3, name: $t("XB_HasEnded") }, // 已结束
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      taskName: "",
      status: 0,
      listLoading: true,
      currDetail: null,
      listShow: true,
      detailShow: false,
    });

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      collectList({
        page: state.currentPage,
        pageSize: 15,
        taskName: state.taskName,
        status: state.status,
        order: 2,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    const viewDetail = (item) => {
      state.currDetail = JSON.parse(JSON.stringify(item));
      state.listShow = false;
      state.detailShow = true;
    };

    const detailBack = () => {
      state.listShow = true;
      state.detailShow = false;
    };

    getList();

    let msgDemandId = ls.get("msgDemandId") || 0;
    if (msgDemandId) {
      ls.remove("msgDemandId");
      collectDetail(Number(msgDemandId)).then((res) => {
        viewDetail(res.data);
      });
    }

    return {
      dateFormat,
      ...toRefs(state),
      switchStatus,
      pageChange,
      onSearch,
      viewDetail,
      detailBack,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  cursor: pointer;

  .bg-green {
    background-color: #22cf33;
  }

  .bg-gray {
    background-color: #818181;
  }

  .bg-orange {
    background-color: #e8673e;
  }

  .detail {
    overflow: hidden;

    h5 {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 0 0;
      transition: all 0.3s ease;
      .mixinEllipsis(26px);

      .status {
        display: inline-block;
        line-height: 26px;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        margin-right: 12px;
        font-weight: normal;
      }
    }
  }

  &.list {
    display: block;
    position: relative;
    .mixinFlex(space-between; center);

    .detail {
      padding: 0 12px;
      width: calc(100% - 302px);

      .row {
        color: #666;
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0 0 0;
        .mixinEllipsis(20px);
      }
    }

    .btns {
      width: 120px;
      .mixinFlex(flex-end; center);

      .btn {
        height: 32px;
        color: #666;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        padding: 2px 20px;
        text-align: center;
        border-radius: 8px;
        border: 1px solid #cacaca;
        transition: all 0.3s ease;
        user-select: none;

        &:hover {
          background-color: @color-theme;
          border: 1px solid @color-theme;
          color: #fff;
        }
      }
    }

    &:hover {
      .detail {
        h5 {
          color: #377dff;
        }
      }
    }
  }
}
</style>
